import api from '../axios';
import { ENDPOINT_DEV } from '../configs';

export const updateOrgAdminByOrgIdAPI = async (orgPortal: any) => {
    return await api
        .put(ENDPOINT_DEV + `organization/${orgPortal.orgId}/org-admin/${orgPortal.email}`, orgPortal.updatedOrgAdmin)
        .then((res) => {
            return res;
        });
};
